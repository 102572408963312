jQuery(document).ready(function($) {
	$( "#remove-job-type" ).click(function() {
		var jobId = $('#job-type-list').val();
		axios.delete('/job-type/' + jobId, {
		    jobId: jobId,
		})
		.then(function (response) {
		    alert('Job Type Removed');
		    updateJobTypeList();
		})
		.catch(function (error) {
		    console.log(error);
		    alert('Error, please check log');
		});
	});
	$( "#add-job-type" ).click(function() {
	    var jobName = $('#new-job-type').val();
	    var companyId = $('#new-job-type').data('company');

	    axios.post('/job-type', {
		    jobName: jobName,
		    companyId: companyId
		})
		.then(function (response) {
		    alert('Job Type Added');
		    updateJobTypeList();
		})
		.catch(function (error) {
		    console.log(error);
		    alert('Error, please check log');
		});

	});
	function updateJobTypeList()
	{
		var companyId = $('#new-job-type').data('company');
		
		axios.get('/job-type/' + companyId)
		  .then(function (response) {
		  	$('#job-type-list').empty();
		  	$('#new-job-type').val('');
		  	$.each( response.data, function( key, value ) {
				  $('#job-type-list').append('<option value=' + value.id + '>' + value.job_type_name +'</option>');
			});
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}
});
